import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, context) => {
	const { globalVariableWithURL = [] } = context;
	const { courseCatalog, description, label, headline } = props;
	const showCardView = courseCatalog[0]?.component === "courseSelectorCards";

	const transformCourseContent = () => {
		if (showCardView)
			return courseCatalog[0]?.cardItem.map((course) => {
				const cardHasImage =
					course?.courseIcon?.[0]?.component === "leanerImageWithHeight";
				let icon = null;
				if (cardHasImage) {
					icon = {
						type: "image",
						url: course?.courseIcon?.[0]?.image?.image,
						alt: course?.courseIcon?.[0]?.image?.altTag,
						height: course?.courseIcon?.[0]?.height,
					};
				} else {
					icon = course?.courseIcon[0]?.iconName;
				}
				return {
					name: course?.courseName,
					icon,
					badge:
						course?.badge && course.badge.length
							? {
									text: course?.badge[0]?.text,
									textColor: course?.badge[0]?.textColor?.color,
									bgColor: course?.badge[0]?.backgroundColor?.color,
								}
							: undefined,
					programName: course?.programName,
					programDescription: course?.programDescription,
					ctaLink: course?.ctaLink[0],
				};
			});
		return {
			courseSelectorFooter: courseCatalog[0]?.courseSelectorFooter[0],
			courseSelectorTitle: courseCatalog[0]?.courseSelectorTitle,
			courseSelectorContent: courseCatalog[0]?.courseSelectorContent.map(
				(course) => {
					return {
						name: course?.courseName,
						icon: course?.courseIcon[0]?.iconName,
						media: course?.media,
						heading: course?.heading,
						description: course?.description,
						items: course?.courseSelectorItem,
					};
				},
			),
		};
	};

	return {
		...props,
		content: transformCourseContent(),
		showCardView,
		courseCatalog,
		description: richTextResolver(description, globalVariableWithURL),
		label: label?.[0],
		headline,
		globalVariableWithURL,
		context,
	};
};
