import { BlockTransform } from "../../Blocks";

const transformers = {
	...BlockTransform,
};

export default (body, context) => {
	const sections = body.map(
		({
			hashLink,
			pageSection,
			component: type,
			backgroundImage,
			backgroundColor,
			isSeparatorOnBottom = false,
			components = [],
		}) => {
			return {
				type,
				props: {
					hashLink,
					pageSection,
					backgroundImage,
					backgroundColor: backgroundColor?.color,
					isSeparatorOnBottom,
				},
				blocks: components.map(({ component: type, ...props }) => {
					if (transformers[type]) {
						return {
							type,
							props: transformers[type](props, context),
						};
					} else {
						console.log(`TRANFORMER FOR BLOCK ${type} NOT FOUND!`);
						return { type, props: props };
					}
				}),
			};
		},
	);
	return sections;
};
