export default (props) => {
	const { label, headline, description, reviewCardsGrid, hasDarkBackground } =
		props;

	const { areCardsOutlined, outlineColor, singleColumn, reviewCards } =
		reviewCardsGrid?.[0];

	return {
		label,
		headline,
		description,
		cta: props?.cta?.[0] || null,
		singleColumn,
		hasDarkBackground,
		areCardsOutlined,
		outlineColor,
		reviewCards,
	};
};
