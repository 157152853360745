import getSections from "../../Sections/FullWidthSection/transform";
import { BlockTransform } from "../../Blocks";
import {
	headerTransformRebrand,
	footerTransformRebrand,
} from "@website-builder/ui/shared";
import { addToDataLayer } from "@website-builder/utilities/utils/utils.js";

export const getBlockProps = (type, props, context) => {
	return BlockTransform[type](props, context);
};

// Used in AdvanceCareer and Prereq Blocks
export const transformButtonCta = (cta) => {
	if (!cta) return null;

	const {
		buttonText = "",
		color: { color = "" } = {},
		link: { type: linkType = "", link = "", target = "_self" } = {},
		type = "",
	} = cta;

	return {
		text: buttonText,
		color: color,
		link: {
			type: linkType,
			value: link,
			target,
		},
		type,
	};
};

//For website redesign
export const getLayoutNew = (layout) => {
	if (!layout || !layout.content) return {};
	const { content = {} } = layout;
	const {
		isHeaderSticky = false,
		isHeaderLight = false,
		headerCTA = [],
		pencilBanner = {},
	} = layout;
	const {
		trackingScripts: [trackingScripts = {}] = [],
		otherScripts = [],
		header: [headerInput = null],
		footer: [footerInput = null],
		isPaidLander = false,
	} = content;

	// page_type is extracted from layout and passed along with all GA4 event
	isPaidLander
		? addToDataLayer({ page_type: "PAID" })
		: addToDataLayer({ page_type: "UNPAID" });

	const header = headerTransformRebrand({
		...headerInput,
		isHeaderSticky,
		isHeaderLight,
		headerCTA,
		isPaidLander,
		pencilBanner,
	});
	const footer = footerTransformRebrand({ ...footerInput, isPaidLander });

	delete trackingScripts._editable;
	delete trackingScripts.component;
	delete trackingScripts._uid;

	return {
		header,
		footer,
		scripts: {
			trackingScripts: [trackingScripts],
			otherScripts: otherScripts.map(
				({ src = "", body = "", async = true, script_location = "head" }) => ({
					src,
					body,
					async,
					script_location,
				}),
			),
		},
	};
};

export default (
	story,
	{
		apiBaseUrl = "",
		globalHostName = "",
		assetPrefixPath = "",
		scriptPrefixPath = "",
		isStaticBuild = false,
	},
) => {
	if (
		!apiBaseUrl ||
		(isStaticBuild &&
			(!assetPrefixPath || !scriptPrefixPath || !globalHostName))
	) {
		throw new Error(
			"apiBaseUrl, globalHostName, assetPrefixPath and scriptPrefixPath are required. Please add them in the environment file",
		);
	}

	try {
		const { full_slug, content, slug } = story;
		const isStoryLayout = full_slug.indexOf("layouts/") !== -1;

		let {
			workshop_id = null,
			typeformLink = null,
			springformId = "",
			indiaPageRedirectionSlug = "/",
			data = {},
			filter_tag = {},
			// course_slug is only available for pages with content-type PostApp in storyblok
			// If you need this value for any other page you can add it to the schema of that particular content-type.
			course_slug = "",
			dripTagPrefix = "",
		} = content;

		let pageAnalytics = [];
		pageAnalytics =
			content?.pageAnalytics?.value.length && content?.pageAnalytics?.value;
		let context = {
			full_slug,
			slug,
			workshop_id: workshop_id ? parseInt(workshop_id, 10) : null,
			course_slug,
			typeformLink,
			springformId,
			apiBaseUrl,
			globalHostName,
			indiaPageRedirectionSlug,
			data,
			filter_tag,
			assetPrefixPath,
			scriptPrefixPath,
			dripTagPrefix: dripTagPrefix,
			isStaticBuild,
		};

		let schema = null,
			storyblokLayout = null,
			body = [],
			href_lang_definitions = [];

		if (isStoryLayout) {
			// If the Story is a Layout then the layout object is same as the story.
			storyblokLayout = story;
		} else {
			storyblokLayout = content.layout;
			body = content.body;
			schema =
				content.schema && content.schema.length ? content.schema[0] : null;
			href_lang_definitions =
				content.href_lang_definitions && content.href_lang_definitions.length
					? content.href_lang_definitions
					: null;
		}

		// const helmet = getHelmet(schema);

		//For website redesign
		const layout = getLayoutNew({
			...storyblokLayout,
			isHeaderSticky: content.isHeaderSticky,
			isHeaderLight: content.isHeaderLight,
			headerCTA: content.navHeaderCta,
			pencilBanner: content.pencilBanner?.[0],
		});

		const sections = getSections(body, context);
		pageAnalytics = pageAnalytics?.reduce(function (map, obj) {
			map[obj.key] = obj.value === "true" ? true : false;
			return map;
		}, {});

		const page = {
			storyData: story,
			// helmet,
			context,
			layout,
			sections,
			href_lang_definitions,
			data,
			filter_tag,
			pageAnalytics,
		};

		return {
			page,
		};
	} catch (err) {
		console.log(err);
	}
};
