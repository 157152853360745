import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
import { AlumniBarTransform } from "@website-builder/ui/shared/components/transformers";

const isB2U = isBusinessVerticalB2U();

export default (props, context) => {
	const { globalVariableWithURL = [], slug, apiBaseUrl } = context;
	const {
		icon,
		label,
		heading,
		subHeading,
		text,
		h1Content,
		bottomBorderColor,
		brandBar,
		listPointers,
		form,
		multiStepForm,
		alumniBar,
		cta,
		learningPathPopup = [],
		imageSection,
		rightSection,
		hasDarkBackground,
		lineGraphicVariant,
		hasBackgroundImage,
		footer,
		programInfoCard,
		hideProgramInfoInMobile,
		removeAdditionalTextSpace,
		isContentCentered,
		backgroundVideo,
		darkenVideo = false,
		starRatingWithLogo,
	} = props;
	const rightSectionObj = rightSection?.[0] ? {} : null;
	if (rightSectionObj) {
		rightSectionObj.partnership = rightSection?.[0].partnership?.[0] || null;
		rightSectionObj.brandBar = rightSection?.[0].brandBar?.[0] || null;
		rightSectionObj.text = richTextResolver(
			rightSection?.[0]?.text,
			globalVariableWithURL,
		);
		rightSectionObj.cta =
			rightSection?.[0].cta.length > 0 ? rightSection?.[0].cta : null;
		rightSectionObj.form = rightSection?.[0].form?.[0] || null;
	}
	const generateValueIconItems = (iconData) => {
		return iconData.map((iconData = {}) => {
			const { icon: [{ iconName = "", size = "32", _uid }] = [], text } =
				iconData;
			return {
				icon: iconName,
				iconSize: parseInt(size, 10) / 16,
				text,
				id: _uid,
			};
		});
	};
	let fetchApiUrl = "";
	const shortName = programInfoCard?.[0]?.shortName;
	if (isB2U) {
		const apiBaseUrl = process.env.GATSBY_API_BASE_URL;
		const workshopEnrollmentDetailsUrl = `${apiBaseUrl}/workshops/microsite/workshop-enrollment-details/`;
		fetchApiUrl = `${workshopEnrollmentDetailsUrl}${shortName}/`;
	} else {
		const enrollmentApiStub = "/workshops/api/workshop-enrollment-details/";
		const apiStubWithSlug = shortName
			? `${enrollmentApiStub}${shortName.replace("/", "")}`
			: `${enrollmentApiStub}${slug}`;
		fetchApiUrl = `${apiBaseUrl}${apiStubWithSlug}`;
	}
	return {
		icon: icon?.[0] || null,
		label,
		heading,
		subHeading,
		text: richTextResolver(text, globalVariableWithURL),
		h1Content,
		bottomBorderColor,
		isContentCentered,
		brandBar: brandBar?.[0] || null,
		listPointers: listPointers?.[0] || null,
		form: form?.[0] || null,
		multiStepForm: multiStepForm?.[0] || null,
		alumniBar: alumniBar?.[0] ? AlumniBarTransform(alumniBar?.[0]) : null,
		cta: cta?.[0] || null,
		learningPathPopup: learningPathPopup?.[0] || null,
		imageSection: imageSection?.[0] || null,
		rightSection: rightSectionObj,
		hasDarkBackground: hasDarkBackground || false,
		lineGraphicVariant,
		hasBackgroundImage,
		globalVariableWithURL,
		footer:
			footer && footer.length
				? generateValueIconItems(footer?.[0]?.valueIconItems)
				: undefined,
		context,
		programInfoProps: programInfoCard?.[0] || {},
		hideProgramInfoInMobile,
		removeAdditionalTextSpace,
		fetchApiUrl,
		backgroundVideo,
		darkenVideo,
		starRatingWithLogo:
			starRatingWithLogo?.length > 0 ? starRatingWithLogo?.[0] : {},
	};
};
