import React from "react";
import { Layouts } from "../../../components/Layout";
import { Sections } from "../../../components/Sections";
import { Blocks } from "../../../components/Blocks";
import { PageNotFound } from "@website-builder/ui/shared/components";
import { storyblokTransformer } from "../../../components/Transformers";
import {
	addToDataLayer,
	setCookie,
} from "@website-builder/utilities/utils/utils.js";

const BlockList = { ...Blocks, PageNotFound };

const GenericLayout = ({ story, basicConfig = {}, storyList = [] }) => {
	// For Influencer and Partner Pages only | Set discountCode cookie
	if (
		story?.content?.discountCode &&
		process.env.GATSBY_ENVIRONMENT === "production"
	) {
		// Set DiscountCode cookie with 30 days expiry
		setCookie(
			"discountCode",
			story?.content?.discountCode,
			2592000000,
			".springboard.com",
		);
	}
	// line_of_business is extracted from page_properties and passed along with all GA4 event
	addToDataLayer({
		line_of_business: story?.content?.lineOfBusiness,
		page_workshop_id: story?.content?.workshop_id,
		page_course_slug: story?.slug,
	});
	const { transformerParams } = basicConfig;

	story = storyblokTransformer(story, transformerParams);

	if (story) {
		const {
			page: {
				layout: { header, footer },
				context,
				sections,
			},
		} = story;

		const HeaderFooter = Layouts["HeaderFooter"];

		return (
			<HeaderFooter header={header} footer={footer} context={context}>
				{sections.map(({ type, blocks = [], props }, index) => {
					const Section = Sections[type];
					const { hashLink, pageSection } = props;
					return (
						<Section
							context={context}
							key={`${type}--${index}`}
							type={type}
							{...props}
						>
							{blocks.map(({ type, props }, index) => {
								if (BlockList[type]) {
									const Block = BlockList[type];
									return (
										<Block
											type={type}
											key={`${type}--${index}`}
											hashLink={hashLink}
											pageSection={pageSection}
											workshopId={context?.workshop_id}
											context={context}
											{...props}
										/>
									);
								} else {
									console.error(`BLOCK : ${type} NOT FOUND!`);
									return;
								}
							})}
						</Section>
					);
				})}
			</HeaderFooter>
		);
	} else {
		console.error("TRANSFORMER DID NOT RETURN A VALID STORY!");
		return null;
	}
};

export default GenericLayout;
