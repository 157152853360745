export default (
	{ label, headline, statsItem, cta },
	{ globalVariableWithURL = [] },
) => {
	return {
		label,
		headline,
		statsItem,
		cta: cta[0],
		globalVariableWithURL,
	};
};
