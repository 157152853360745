import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const {
		leftSection,
		description,
		bottomDescription,
		logoSection: [logoSection] = [],
		cta: ctaData = [],
		isDarkBg,
		listBulletColor,
		customIconFillColor: {
			color: customIconFillColor = "var(--primary-green-400)",
		} = {},
		customIconStrokeColor: {
			color: customIconStrokeColor = "var(--primary-forest-400)",
		} = {},
		starRatingWithLogo,
	} = props;
	const cta = (ctaData?.length && props.cta[0]) || {};
	return {
		...props,
		description: richTextResolver(description, globalVariableWithURL),
		bottomDescription: richTextResolver(
			bottomDescription,
			globalVariableWithURL,
		),
		leftSection: (leftSection?.length && props.leftSection[0]) || {},
		customIconFillColor,
		customIconStrokeColor,
		listBulletColor: formatColor(listBulletColor),
		cta,
		logoSection,
		globalVariableWithURL,
		isDarkBg,
		starRatingWithLogo:
			starRatingWithLogo?.length > 0 ? starRatingWithLogo[0] : {},
	};
};
