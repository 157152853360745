import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
export default (
	{
		cards,
		darkBg,
		description,
		heading,
		mobileLayout,
		layout,
		backgroundColor,
		quoteColor,
		showNavigationInMobile,
		cta,
	},
	{ globalVariableWithURL = [] },
) => {
	const transformCard = ({
		companyLogo,
		image,
		label,
		quote,
		studentName,
		studentTransition,
		studentWork,
		_uid,
	}) => {
		return {
			logoURL: companyLogo?.image,
			logoAlt: companyLogo?.altTag,
			imageURL: image?.image,
			imageAlt: image?.altTag,
			label,
			quote,
			studentName,
			studentTransition,
			studentWork,
			_uid,
		};
	};

	return {
		cards: cards.map(transformCard),
		darkBg,
		description: richTextResolver(description, globalVariableWithURL),
		heading,
		mobileLayout,
		layout,
		showNavigationInMobile: showNavigationInMobile.value,
		cta: cta.length ? cta[0] : null,
		backgroundColor: formatColor(backgroundColor),
		quoteColor: formatColor(quoteColor),
	};
};
