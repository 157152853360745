import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const transformCardItem = () => {
		return props.dualCTACardItems.map((item) => {
			const { description, heading, isDarkBg } = item;
			return {
				backgroundColor: item?.backgroundColor?.color,
				cta: item?.cta?.[0],
				media: item?.media?.[0],
				description: richTextResolver(description, globalVariableWithURL),
				isDarkBg,
				heading,
			};
		});
	};
	return {
		dualCTACardItems: transformCardItem(),
	};
};
