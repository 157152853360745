const isB2U = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";
export default (props) => {
	const {
		hashLinks = [],
		portalId,
		formId,
		globalDomain,
		navigationWithoutCTA,
		requestFormType,
		ctaButton: [ctaInput = null] = [],
		callUs: [callUsInput = null] = [],
		showRequestSyllabusInMobile = false,
		showCallUsInMobile = false,
		requestSyllabusCTA,
	} = props;

	let callUsBtn = {};
	if (callUsInput) {
		callUsBtn.upperText = callUsInput.upperText || "";
		callUsBtn.lowerText = callUsInput.lowerText || "";
		callUsBtn.tel = callUsInput.tel || "";
		if (callUsInput.image.length !== 0) {
			callUsBtn.url = callUsInput.image[0]?.image?.image || "";
			callUsBtn.alt = callUsInput.image[0]?.image?.altTag || "";
		}
	}

	let cta = null;
	if (ctaInput) {
		const {
			buttonText = "",
			event = "None",
			link: { type: linkType = "", link = "", target = "_self" } = {},
			type = "",
			modalConfiguration = [],
		} = ctaInput;

		cta = {
			...ctaInput,
			text: buttonText,
			link: {
				type: linkType,
				link: link,
				target,
			},
			type,
			event,
			modalConfiguration,
		};
	}
	return {
		cta,
		hashLinks: hashLinks.map(
			({ label = "", link = { type: null, link: null } }) => ({
				label,
				link: { type: link.type, value: link.link },
			}),
		),
		portalId,
		formId,
		globalDomain,
		navigationWithoutCTA:
			typeof navigationWithoutCTA === "object"
				? navigationWithoutCTA.value
				: navigationWithoutCTA,
		requestFormType,
		callUsBtn: { ...callUsInput, ...callUsBtn },
		showRequestSyllabusInMobile,
		showCallUsInMobile,
		requestSyllabusCTA: isB2U ? requestSyllabusCTA?.[0] : undefined,
	};
};
