export default (props) => {
	const headlines = transformTextProp(props.headlines);
	const textItems = transformTextProp(props.textItems);

	let cta = {};
	if (props.cta && props.cta[0]) {
		const { text = "", buttonText = "", event = "" } = props.cta[0];
		cta = {
			...props.cta[0],
			text: buttonText || text,
			event: event || "link_click",
			isButton: !!buttonText,
			align: props.ctaAlign,
		};
	}

	let colWidth = [2, 10];
	try {
		const widthArr = JSON.parse(props.width);
		colWidth = Array.isArray(widthArr) ? widthArr : [2, 10];
	} catch {
		console.error("parse error");
	}

	const blockStyle = {
		paddingTop: props.marginTop || "40px",
		paddingBottom: props.marginBottom || "40px",
		textAlign: props.alignContent || "left",
	};

	return {
		labels: props.labels || [],
		headlines,
		textItems,
		cta,
		colWidth,
		blockStyle,
		isDarkBg: props.darkBg || false,
	};
};

const transformTextProp = (prop) => {
	return prop.map(({ variant, content, marginBottom, align }) => {
		const textStyle = {};
		marginBottom && (textStyle.marginBottom = marginBottom);
		align && (textStyle.textAlign = align);
		return {
			variant,
			content,
			textStyle,
		};
	});
};
