import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const portfolios = props.portfolios?.map(
		({ image, coverImage, subtitle, link = [], description, ...restProps }) => {
			const linkDetails = link?.[0] || {};
			const imageDetails = image?.[0] || {};
			const coverImageDetails = coverImage?.[0] || {};
			return {
				image: imageDetails?.image || {},
				coverImage: coverImageDetails?.image || {},
				subtitle: subtitle?.toUpperCase(),
				link: linkDetails,
				description: richTextResolver(description, globalVariableWithURL),
				...restProps,
			};
		},
	);
	return {
		...props,
		sectionLabel: props.sectionLabel?.toUpperCase(),
		cta: props.cta?.[0] || {},
		description: props.description
			? richTextResolver(props.description, globalVariableWithURL)
			: "",
		list:
			props.list?.length &&
			props.list.map(({ description = "" }) => ({
				itemDescription: richTextResolver(description, globalVariableWithURL),
			})),
		portfolios,
		cardColorInDarkBg: props.cardColorInDarkBg?.color,
		cardColorInLightBg: props.cardColorInLightBg?.color,
	};
};
