import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const {
		isDarkBackground,
		link: [{ text: linkLabel, link } = {}] = [],
		logoSection: [
			{
				labelText: logoHeader = "",
				brandLogos: logos = [],
				maxLogosInRow = 0,
			} = {},
		] = [],
		listHeader,
		description,
		header,
		subHeader,
		topic = "",
		list = [],
		descriptionBottom,
	} = props;
	return {
		isDarkBackground,
		link,
		linkLabel,
		listHeader,
		description: richTextResolver(description, globalVariableWithURL),
		logoHeader: logoHeader?.toUpperCase(),
		logos,
		maxLogosInRow: parseInt(maxLogosInRow, 10),
		header,
		subHeader,
		topic: topic?.toUpperCase(),
		list,
		globalVariableWithURL,
		descriptionBottom: richTextResolver(
			descriptionBottom,
			globalVariableWithURL,
		),
	};
};
