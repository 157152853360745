export default ({ valueIconItems: iconData = [] }) => {
	const valueIconItems = iconData.map((iconData = {}) => {
		const { icon: [{ iconName = "", size = "32", _uid }] = [], text } =
			iconData;
		return {
			icon: iconName,
			iconSize: parseInt(size, 10) / 16,
			text,
			id: _uid,
		};
	});
	return {
		valueIconItems,
	};
};
