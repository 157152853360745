import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const {
		header = "",
		headerLabel = "",
		hasDarkBackground = false,
		subHeader = "",
		itemsList = [],
	} = props;

	const transformItems = (item) => {
		return {
			itemDescription:
				richTextResolver(item?.itemDescription, globalVariableWithURL) || "",
			itemHeader: item?.itemHeader || "",
			itemSubHeader: item?.itemSubHeader || "",
			image:
				{
					url: item?.media[0]?.image?.image,
					alt: item?.media[0]?.image?.alt,
				} || null,
			video:
				{
					image: item?.media[0]?.videoImage?.image,
					url: item?.media[0]?.videoUrl?.url,
				} || null,
			cta: item?.itemLink.length
				? {
						link: {
							type: "internal",
							link: item?.itemLink[0]?.link?.link,
							target: item?.itemLink[0]?.link?.target || "_self",
						},
						event: "link_click",
						text: item?.itemLink[0]?.label,
					}
				: null,
		};
	};

	return {
		header,
		headerLabel,
		hasDarkBackground,
		subHeader,
		itemsList: itemsList.map(transformItems),
	};
};
