import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

export default (props, { globalVariableWithURL }) => {
	const { title, description, removeTopSpacing } = props;
	const generateMentorCardItems = () =>
		props.mentorCardItems.map((mentor) => ({
			...mentor,
			companyLogo: mentor?.companyLogo?.[0],
			profileImage: mentor?.profileImage?.[0],
		}));

	return {
		mentorCardItems: generateMentorCardItems(),
		title,
		description: richTextResolver(description, globalVariableWithURL),
		removeTopSpacing,
	};
};
