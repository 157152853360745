import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";

export default (props, { globalVariableWithURL = [] }) => {
	const {
		cards,
		label,
		description,
		headline,
		isDarkBg,
		buttonAboveCards,
		buttonBelowCards,
		starRatingWithLogo,
	} = props;

	const transformCardItems = () => {
		return {
			cardItems: cards?.[0]?.cardItems?.map(
				({ heading, icon, description }) => {
					return {
						icon: icon[0],
						description: description
							? richTextResolver(description, globalVariableWithURL)
							: undefined,
						heading,
					};
				},
			),
			showNumber: cards?.[0]?.showNumber,
			numberColor: formatColor(cards?.[0]?.numberColor),
			hasBoxShadow: cards?.[0]?.hasBoxShadow,
		};
	};
	return {
		label: label?.[0],
		description: description
			? richTextResolver(description, globalVariableWithURL)
			: undefined,
		headline,
		cards: transformCardItems(),
		isDarkBg,
		buttonAboveCards: buttonAboveCards?.[0] || null,
		buttonBelowCards: buttonBelowCards?.[0] || null,
		starRatingWithLogo:
			starRatingWithLogo?.length > 0 ? starRatingWithLogo[0] : {},
	};
};
