export default ({
	heading,
	headingColor,
	description,
	descriptionColor,
	link,
	lineGraphicColor,
}) => {
	return {
		heading,
		headingColor: headingColor.color,
		description,
		descriptionColor: descriptionColor.color,
		link: link[0] || null,
		lineGraphicColor: lineGraphicColor.color,
	};
};
