import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const {
		contentDetails = "",
		carouselData: [carouselDataContainer = {}] = [],
		description = {},
	} = props;
	let content = carouselDataContainer?.content;
	content = content.map(({ component, ...contentData }) => {
		if (component === "leanerImage") {
			const { component, image } = contentData;
			return { ...contentData, imageUrl: image?.image || "", component };
		} else {
			return contentData;
		}
	});
	return {
		...props,
		contentDetails: contentDetails?.toUpperCase(),
		carouselData: content,
		component: carouselDataContainer?.component,
		description: richTextResolver(description, globalVariableWithURL),
		globalVariableWithURL,
	};
};
