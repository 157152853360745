import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (
	{
		header,
		description,
		table,
		footerText,
		highlightedColumn,
		highlightColor,
		isDarkBg,
		showCompactTableInMobile,
		compactTableHeaderColor,
	},
	{ globalVariableWithURL = [] },
) => {
	return {
		header,
		description: richTextResolver(description, globalVariableWithURL),
		table,
		footer: footerText.map((item) => {
			return richTextResolver(item?.bulletPoint, globalVariableWithURL);
		}),
		highlightedColumn,
		highlightColor,
		isDarkBg,
		showCompactTableInMobile,
		compactTableHeaderColor,
	};
};
