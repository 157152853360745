import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { AlumniBarTransform } from "@website-builder/ui/shared/components/transformers";

export default (props, context) => {
	const { globalVariableWithURL = [] } = context;
	const transformItem = (item) => {
		switch (item.component) {
			case "textWR":
				return {
					component: item.component,
					text: richTextResolver(item?.text, globalVariableWithURL),
				};

			case "brandBarSimple":
			case "ButtonWR":
			case "Rating":
				return item;

			case "phoneEmailCta":
				const callUsModalDetails = item?.callUsModalDetails?.[0];
				return {
					component: item.component,
					imageUrl: callUsModalDetails?.image?.image,
					imageAltText: callUsModalDetails?.image?.alt,
					upperText: callUsModalDetails?.upperText,
					lowerText: callUsModalDetails?.lowerText,
					...item,
				};

			case "careerQuizCta":
				return {
					component: item.component,
					label: item.label,
					linkText: item.linkText,
					link: item.link,
					cta: {
						event: BUTTON_INTERACTION.QUIZ_START,
						...item,
					},
				};

			case "EmailApplicationForm":
				return {
					component: item.component,
					label: item.label,
					placeholder: item.placeholder,
					buttonText:
						item.waitlist && item.waitlist.length
							? item.waitlist?.[0]?.buttonText
							: item.buttonText,
					buttonEvent:
						item.waitlist && item.waitlist.length
							? BUTTON_INTERACTION.APPLICATION_START_WAIT_LIST
							: BUTTON_INTERACTION.APPLICATION_START,
					waitlist:
						item.waitlist && item.waitlist.length ? item?.waitlist?.[0] : {},
				};

			case "emailInputWithCTAandText":
				return {
					component: item.component,
					label: item.label,
					placeholder: item.placeholder,
					buttonText:
						item.waitlist && item.waitlist.length
							? item.waitlist?.[0]?.buttonText
							: item.cta[0]?.buttonText,
					buttonEvent:
						item.waitlist && item.waitlist.length
							? BUTTON_INTERACTION.APPLICATION_START_WAIT_LIST
							: item.cta[0]?.event,
					text: richTextResolver(item.text, globalVariableWithURL),
					waitlist:
						item.waitlist && item.waitlist.length ? item?.waitlist?.[0] : {},
				};

			case "textWithArrowCta":
				return {
					component: item.component,
					text: item.Text,
					linkText: item.linkText,
					link: item.link,
					cta: {
						event: "link_click",
						...item,
					},
				};

			case "AlumniBar":
				return {
					component: "AlumniBar",
					...AlumniBarTransform(item),
				};

			case "linksWithSeparatorCta":
				return {
					component: item.component,
					links: item.links?.map((link) => {
						return {
							arrowCta: link.type === "arrowCta",
							iconConfig: link.iconName ? { iconName: link.iconName } : {},
							iconRight: link.iconRight,
							link: link?.link,
							linkText: link.linkText,
							disabled: link.disabled,
							cta: link,
						};
					}),
				};

			default:
				return null;
		}
	};

	const {
		color,
		heading,
		leftSection,
		rightSection,
		lineGraphic,
		starRatingWithLogo,
	} = props;
	return {
		color: JSON.parse(color),
		heading,
		leftSection: leftSection.map(transformItem),
		rightSection: rightSection.map(transformItem),
		lineGraphic,
		context,
		starRatingWithLogo:
			starRatingWithLogo?.length > 0 ? starRatingWithLogo[0] : {},
	};
};
