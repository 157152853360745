export default (props, context) => {
	const { globalVariableWithURL = [] } = context;
	const {
		leftContent = [],
		formDetails = [],
		hasDarkBackground = false,
		hasFormOnLeft = false,
		topBackgroundColor = {},
		bottomBackgroundColor = {},
		itemsOnFirstColumn = "0",
		brandBar = [],
	} = props;
	const isFullWidthForm = leftContent.length > 0 ? false : true;
	const content = {};
	if (!isFullWidthForm) {
		content.headline = leftContent[0].headline;
		content.subcopy = leftContent[0].subcopy;
		content.image = {
			url: leftContent[0].image?.image,
			alt: leftContent[0].image?.altTag,
		};
		const {
			labelText = "",
			brandLogos = [],
			maxLogosInRow = 0,
		} = leftContent[0].logoBar?.[0] || {};
		content.logos = leftContent[0].logoBar?.[0]
			? { labelText, brandLogos, maxLogosInRow }
			: null;
	}
	return {
		content,
		formDetails: formDetails[0] || null,
		isFullWidthForm,
		hasDarkBackground,
		hasFormOnLeft,
		topBackgroundColor: topBackgroundColor?.color,
		bottomBackgroundColor: bottomBackgroundColor?.color,
		itemsOnFirstColumn: parseInt(itemsOnFirstColumn, 10),
		brandBar: brandBar?.[0] || null,
		context,
	};
};
