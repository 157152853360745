import loadable from "@loadable/component";

export const PageNotFound = loadable(() => import("./PageNotFound"));
export const PortfolioShowcase = loadable(() => import("./PortfolioShowcase"));
export const CenteredContent = loadable(() => import("./CenteredContent"));
export const CurriculumWR = loadable(() => import("./CurriculumWR"));
export const SecondaryNavigationWR = loadable(
	() => import("./SecondaryNavigationWR"),
);
export const PricingWR = loadable(() => import("./PricingWR"));
export const CourseSelector = loadable(() => import("./CourseSelector"));
export const RedesignFAQ = loadable(() => import("./RedesignFAQ"));
export const MentorCardList = loadable(() => import("./MentorCardList"));
export const SimplifiedList = loadable(() => import("./SimplifiedList"));
export const MediaWithText = loadable(() => import("./MediaWithText"));
export const AlternateSideContentModule = loadable(
	() => import("./AlternateSideContentModule"),
);
export const LeadFormWR = loadable(() => import("./LeadFormWR"));
export const ApplicationFormWR = loadable(() => import("./ApplicationFormWR"));
export const BoldText = loadable(() => import("./BoldText"));
export const HeroBannerWR = loadable(() => import("./HeroBannerWR"));
export const AlumniBar = loadable(() => import("./AlumniBar"));
export const FormWrapper = loadable(() => import("./FormWrapper"));
export const BragBar = loadable(() => import("./BragBar"));
export const ModalWithForm = loadable(() => import("./ModalWithForm"));
export const HypeCard = loadable(() => import("./HypeCard"));
export const ComparisonTable = loadable(() => import("./ComparisonTable"));
export const Testimonial = loadable(() => import("./Testimonial"));
export const OverlappingCard = loadable(() => import("./OverlappingCard"));
export const FeatureCards = loadable(() => import("./FeatureCards"));
export const ImportantDateCard = loadable(() => import("./ImportantDatesCard"));
export const ProgramInfoPanel = loadable(() => import("./ProgramInfoPanel"));
export const StatsWR = loadable(() => import("./StatsWR"));
export const ReviewsModule = loadable(() => import("./ReviewsModule"));

export * from "./transformers";
