import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import { isBusinessVerticalB2U } from "@website-builder/utilities/utils/helpers.js";
export default (props, context) => {
	const isB2U = isBusinessVerticalB2U();
	const {
		cta = [],
		cardColor = {},
		backendCourseSlug,
		description = {},
		isCardDark,
	} = props;
	const ctaDetails = cta[0] || {};
	const { slug, apiBaseUrl, globalVariableWithURL = [] } = context;
	let enrollmentDetailsApiSlug = "";
	if (isB2U) {
		const workshopEnrollmentDetailsUrl = `/workshops/microsite/workshop-enrollment-details/`;
		enrollmentDetailsApiSlug = `${workshopEnrollmentDetailsUrl}${backendCourseSlug}/`;
	} else {
		const enrollmentApiStub = "/workshops/api/workshop-enrollment-details/";
		enrollmentDetailsApiSlug = backendCourseSlug
			? `${enrollmentApiStub}${backendCourseSlug.replace("/", "")}`
			: `${enrollmentApiStub}${slug}`;
	}
	return {
		...props,
		cta: ctaDetails,
		cardColor: formatColor(cardColor),
		enrollmentDetailsApiSlug,
		isCardDark,
		apiBaseUrl: isB2U ? process.env.GATSBY_API_BASE_URL : apiBaseUrl,
		description: richTextResolver(description, globalVariableWithURL),
	};
};
