import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (
	{
		label,
		headline,
		description,
		headerCTA,
		headerCTAText,
		curriculumContent,
		showNumbers,
	},
	{ globalVariableWithURL = [] },
) => {
	const transformCurriculumContent = () => {
		return curriculumContent?.[0].curriculumTopics.map((topic, index) => {
			return {
				topicNo: index + 1,
				description: richTextResolver(
					topic?.description,
					globalVariableWithURL,
				),
				listItems: topic?.listItems[0]?.list,
				name: topic?.name,
			};
		});
	};

	return {
		label,
		headline,
		description: richTextResolver(description, globalVariableWithURL),
		headerCTA: headerCTA?.[0],
		headerCTAText,
		curriculumContent: {
			curriculumTopics: transformCurriculumContent(),
			filterHeading: curriculumContent?.[0]?.filterHeading,
		},
		showNumbers,
	};
};
