import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

export default (props, { globalVariableWithURL = [] }) => {
	const {
		headline = "",
		label = "",
		description = { html: "" },
		pricingOptions = {},
		pricingWRCard,
	} = props;
	return {
		headline,
		label,
		description: props.description
			? richTextResolver(props.description, globalVariableWithURL)
			: undefined,
		pricingWRCard: pricingWRCard.length
			? {
					headline: pricingWRCard[0]?.headline,
					description: richTextResolver(
						pricingWRCard[0]?.description,
						globalVariableWithURL,
					),
					footerText: pricingWRCard[0]?.footerText,
					media: pricingWRCard[0]?.media[0],
				}
			: undefined,
		pricingOptions: pricingOptions?.value ? pricingOptions : undefined,
	};
};
