import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const {
		link,
		media = {},
		bottomBackgroundColor = {},
		topBackgroundColor = {},
		contentBackgroundColor = {},
		description,
	} = props;
	const linkDetails = link[0] || {};
	const mediaDetails = media?.[0] || {};
	const imageComponent = mediaDetails.component;
	const image =
		imageComponent === "leanerImage"
			? mediaDetails?.image?.image
			: mediaDetails?.videoImage?.image;
	const imageAltTag =
		imageComponent === "leanerImage"
			? mediaDetails?.image?.altTag
			: mediaDetails?.videoImage?.altTag;
	return {
		...props,
		description: richTextResolver(description, globalVariableWithURL),
		link: linkDetails.link?.link,
		target: linkDetails.link?.target,
		linkLabel: linkDetails?.text,
		cta: linkDetails,
		media: media?.[0] || {},
		imageComponent,
		image,
		imageAltTag,
		bottomBackgroundColor: formatColor(bottomBackgroundColor),
		topBackgroundColor: formatColor(topBackgroundColor),
		contentBackgroundColor: formatColor(contentBackgroundColor),
	};
};
