export default (props) => {
	const {
		label,
		headline,
		description,
		maxLogosInRow,
		hasDarkBackground,
		courseReportBadges,
		isPaid,
		starRating,
	} = props;

	const transformLinkWithIcon = ({
		link = { link: "", type: "external", target: "_blank" },
		icon = { image: "", altTag: "" },
	}) => ({
		imageUrl: icon.image,
		alt: icon.altTag,
		cta: {
			event: "brag_bar_logo_click",
			link,
		},
	});

	const logos = props?.logos?.map((logo) => {
		if (logo.hasOwnProperty("link")) {
			return transformLinkWithIcon(logo);
		} else {
			return {
				imageUrl: logo.image?.image || "",
				alt: logo.image?.altTag || "",
				base64: logo.image?.base64 || undefined,
			};
		}
	});
	const transformReportBadges = (courseReportBadges) => {
		if (!courseReportBadges) return [];
		return courseReportBadges.map(
			({
				heading = "",
				description = "",
				image: { image = "", altTag = "" },
				imageUrl = "",
			}) => ({
				heading,
				description,
				image,
				altTag,
				imageUrl,
			}),
		);
	};
	return {
		label,
		headline,
		description,
		logos,
		isPaid,
		maxLogosInRow: maxLogosInRow ? parseInt(maxLogosInRow, 10) : 0,
		hasDarkBackground,
		reportBadges: transformReportBadges(courseReportBadges),
		starRating: starRating?.length > 0 ? starRating[0] : {},
	};
};
