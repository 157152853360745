export default (props) => {
	return {
		...props,
		gridItems: props?.gridItems?.map((item) => ({
			...item,
			media: item?.media?.[0],
		})),
		cta: props?.cta?.[0] || null,
		carouselViewCount: parseInt(props?.carouselViewCount) || 3,
	};
};
