import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

export default (props, context) => {
	const { globalVariableWithURL = [] } = context;
	const {
		backgroundColor,
		salarySection,
		list = [],
		listIconColor,
		ctaDescription,
		cta,
		logoSection: logoSec,
	} = props;
	const salaryDetails = salarySection[0] || {};
	const ctaDetails = cta[0] || {};
	const ctaDesc = richTextResolver(ctaDescription, globalVariableWithURL);
	const { labelText, brandLogos, maxLogosInRow = 0 } = logoSec[0] || {};
	return {
		...props,
		listIconColor: formatColor(listIconColor),
		salarySection: salaryDetails,
		ctaDescription: ctaDesc,
		backgroundColor: formatColor(backgroundColor),
		cta: ctaDetails,
		list,
		logoBarLabel: labelText,
		logoBar: brandLogos,
		maxLogosInRow: parseInt(maxLogosInRow, 10),
		context,
	};
};
