import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

export default ({
	label = [],
	headline = "",
	description = "",
	comparisonCard = [],
	darkBg = false,
}) => {
	return {
		label: label[0],
		headline: headline,
		description: richTextResolver(description),
		cards: comparisonCard,
		darkBg,
	};
};
