export default (props, { globalVariableWithURL = [] }) => {
	const {
		label,
		headline,
		icon,
		alignVideoOn,
		content,
		isDarkBg,
		showCarouselArrows,
		showLineGraphic,
	} = props;

	const transformContent = () => {
		const isStaticContent =
			content[0].component === "staticVideoFeatureTestimonial";
		if (isStaticContent) {
			const staticContent = content[0]?.staticContent[0];
			return {
				isStaticContent,
				content: {
					alumniCard: staticContent?.alumniCard[0],
					studentOutcomes:
						staticContent?.studentOutcomes[0]?.items.length >= 1
							? staticContent?.studentOutcomes[0]?.items
							: undefined,
					cta: staticContent?.cta[0],
					description: staticContent?.description,
				},
				videos:
					content[0].testimonialVideos.length === 1
						? content[0]?.testimonialVideos[0]
						: content[0]?.testimonialVideos,
			};
		}
		const videoItems = [];
		return {
			isStaticContent,
			content: content[0]?.videoFeatureTestimonialDynamicItems.map((item) => {
				videoItems.push(item.video[0]);
				return {
					alumniCard: item?.content[0]?.alumniCard[0],
					studentOutcomes:
						item?.content[0]?.studentOutcomes[0]?.items.length > 1
							? item?.content[0]?.studentOutcomes[0]?.items
							: undefined,
					cta: item?.content[0]?.cta[0],
					description: item?.content[0]?.description,
				};
			}),
			videos: videoItems,
		};
	};

	return {
		label: label[0],
		headline,
		content: transformContent(),
		icon: icon[0],
		isDarkBg,
		alignVideoOn,
		showCarouselArrows,
		showLineGraphic,
		globalVariableWithURL,
	};
};
