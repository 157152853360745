import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
export default (props, { globalVariableWithURL = [] }) => {
	const {
		defaultShowCount = 4,
		panels = [],
		header = "",
		showLinkColor,
		isDarkBg,
	} = props;
	return {
		header,
		accordionItems: panels.map(({ header = "", detail = "" }) => ({
			heading: header,
			content: richTextResolver(detail, globalVariableWithURL),
		})),
		defaultShowCount: parseInt(defaultShowCount, 10),
		showLinkColor,
		isDarkBg,
	};
};
