import loadable from "@loadable/component";
import RedesignCarouselTransform from "./RedesignCarousel/transform";
import ValueIconBarTransform from "./ValueIconBar/transform";
import CTABlockTransform from "./CTABlock/transform";
import DualCTATransform from "./DualCTA/transform";
import MentorGridTransform from "./MentorGrid/transform";
import VideoFeatureTestimonialTransform from "./VideoFeatureTestimonial/transform";
import FormContainerTransform from "./FormContainer/transform";
import TextBlockTransform from "./TextBlock/transform";
import TuitionPlanComparisonTransform from "./TuitionPlanComparison/transform";
import {
	PortfolioShowcaseTransform,
	CenteredContentTransform,
	CurriculumWRTransform,
	SecondaryNavigationWRTransform,
	PricingWRTransform,
	CourseSelectorTransform,
	RedesignFAQTransform,
	MentorCardListTransform,
	SimplifiedListTransform,
	MediaWithTextTransform,
	AlternateSideContentModuleTransform,
	AlumniBarTransform,
	HeroBannerWRTransform,
	BragBarTransform,
	PageNotFoundTransform,
	ComparisonTableTransform,
	HypeCardTransform,
	TestimonialTransform,
	OverlappingCardTransform,
	FeatureCardsTransform,
	ImportantDateCardTransform,
	StatsWRTransform,
	ReviewsModuleTransform,
} from "@website-builder/ui/shared/components/transformers";
import ContentGridTransform from "./ContentGrid/transform";

export const Blocks = {
	ValueIconBar: loadable(() => import("./ValueIconBar")),
	RedesignCarousel: loadable(() => import("./RedesignCarousel")),
	CTABlock: loadable(() => import("./CTABlock")),
	DualCTA: loadable(() => import("./DualCTA")),
	MentorGrid: loadable(() => import("./MentorGrid")),
	VideoFeatureTestimonial: loadable(() => import("./VideoFeatureTestimonial")),
	FormContainer: loadable(() => import("./FormContainer")),
	TextBlock: loadable(() => import("./TextBlock")),
	TuitionPlanComparison: loadable(() => import("./TuitionPlanComparison")),
	PortfolioShowcase: loadable(
		() => import("../../../../shared/src/components/PortfolioShowcase"),
	),
	CenteredContent: loadable(
		() => import("../../../../shared/src/components/CenteredContent"),
	),
	CurriculumWR: loadable(
		() => import("../../../../shared/src/components/CurriculumWR"),
	),
	SecondaryNavigationWR: loadable(
		() => import("../../../../shared/src/components/SecondaryNavigationWR"),
	),
	PricingWR: loadable(
		() => import("../../../../shared/src/components/PricingWR"),
	),
	CourseSelector: loadable(
		() => import("../../../../shared/src/components/CourseSelector"),
	),
	RedesignFAQ: loadable(
		() => import("../../../../shared/src/components/RedesignFAQ"),
	),
	MentorCardList: loadable(
		() => import("../../../../shared/src/components/MentorCardList"),
	),
	SimplifiedList: loadable(
		() => import("../../../../shared/src/components/SimplifiedList"),
	),
	MediaWithText: loadable(
		() => import("../../../../shared/src/components/MediaWithText"),
	),
	AlternateSideContentModule: loadable(
		() =>
			import("../../../../shared/src/components/AlternateSideContentModule"),
	),
	AlumniBar: loadable(
		() => import("../../../../shared/src/components/AlumniBar"),
	),
	HeroBannerWR: loadable(
		() => import("../../../../shared/src/components/HeroBannerWR"),
	),
	BragBar: loadable(() => import("../../../../shared/src/components/BragBar")),
	ComparisonTable: loadable(
		() => import("../../../../shared/src/components/ComparisonTable"),
	),
	HypeCard: loadable(
		() => import("../../../../shared/src/components/HypeCard"),
	),
	Testimonial: loadable(
		() => import("../../../../shared/src/components/Testimonial"),
	),
	OverlappingCard: loadable(
		() => import("../../../../shared/src/components/OverlappingCard"),
	),
	FeatureCards: loadable(
		() => import("../../../../shared/src/components/FeatureCards"),
	),
	ImportantDateCard: loadable(
		() => import("../../../../shared/src/components/ImportantDatesCard"),
	),
	PageNotFound: loadable(
		() => import("../../../../shared/src/components/PageNotFound"),
	),
	StatsWR: loadable(() => import("../../../../shared/src/components/StatsWR")),
	ContentGrid: loadable(() => import("./ContentGrid")),
	ReviewsModule: loadable(
		() => import("../../../../shared/src/components/ReviewsModule"),
	),
};

export const BlockTransform = {
	SimplifiedList: SimplifiedListTransform,
	RedesignCarousel: RedesignCarouselTransform,
	HeroBannerWR: HeroBannerWRTransform,
	AlternateSideContentModule: AlternateSideContentModuleTransform,
	AlumniBar: AlumniBarTransform,
	ValueIconBar: ValueIconBarTransform,
	OverlappingCard: OverlappingCardTransform,
	ImportantDateCard: ImportantDateCardTransform,
	HypeCard: HypeCardTransform,
	CenteredContent: CenteredContentTransform,
	CTABlock: CTABlockTransform,
	SecondaryNavigationWR: SecondaryNavigationWRTransform,
	CourseSelector: CourseSelectorTransform,
	DualCTA: DualCTATransform,
	StatsWR: StatsWRTransform,
	ComparisonTable: ComparisonTableTransform,
	BragBar: BragBarTransform,
	RedesignFAQ: RedesignFAQTransform,
	PricingWR: PricingWRTransform,
	MentorGrid: MentorGridTransform,
	Testimonial: TestimonialTransform,
	CurriculumWR: CurriculumWRTransform,
	VideoFeatureTestimonial: VideoFeatureTestimonialTransform,
	MentorCardList: MentorCardListTransform,
	MediaWithText: MediaWithTextTransform,
	FeatureCards: FeatureCardsTransform,
	FormContainer: FormContainerTransform,
	TextBlock: TextBlockTransform,
	TuitionPlanComparison: TuitionPlanComparisonTransform,
	PortfolioShowcase: PortfolioShowcaseTransform,
	ContentGrid: ContentGridTransform,
	ImportantDateCard: ImportantDateCardTransform,
	PageNotFound: PageNotFoundTransform,
	ReviewsModule: ReviewsModuleTransform,
};

export default Blocks;
