import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

export default (
	{
		alignMediaOn,
		alignMediaOnMobile,
		showLineGraphic,
		isDarkBg,
		label,
		headline,
		description,
		media,
		cta,
		isJobGuaranteeModule,
		isImageOnBottomInMobileView,
		linkColor,
		starRatingWithLogo,
	},
	{ globalVariableWithURL = [] },
) => {
	return {
		alignMediaOn,
		alignMediaOnMobile,
		showLineGraphic,
		isDarkBg,
		label: label?.[0],
		headline,
		description: description
			? richTextResolver(description, globalVariableWithURL)
			: undefined,
		media: media?.[0],
		cta: cta?.[0],
		isJobGuaranteeModule,
		isImageOnBottomInMobileView,
		linkColor,
		starRatingWithLogo:
			starRatingWithLogo?.length > 0 ? starRatingWithLogo[0] : {},
	};
};
